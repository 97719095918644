<template>
  <div v-if="parents && parents.length" class="breadcrumbs">
    <ul class="breadcrumbs__list">
      <li v-for="parent in parents" :key="parent.id">
        <router-link :to="{ name: parent.route }" class="breadcrumbs__link">
          <span>{{ parent.title }}</span>
          <IconComponent name="arrow-right-r-line" />
        </router-link>
      </li>
      <li v-for="child in children" :key="child.id">
        <router-link :to="child.route" v-if="child.route">
          <span>{{ child.title }}</span>
        </router-link>
        <span v-else>{{ child.title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "BreadcrumbsComponent",
  components: { IconComponent },
  props: {
    links: {
      type: Array,
      required: true,
      parent: {
        type: Object,
        required: false,
      },
      children: {
        type: Object,
        required: true,
      },
    },
  },
  computed: {
    parents() {
      return [
        { title: "Главная", route: "home" },
        this.$route.meta.parent || [],
        this?.links[0]?.parent || [],
        // this.links.map(item => item.parent || null) || [],
      ].flat();
    },
    children() {
      return this.links.map((item) => item.children);
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.breadcrumbs {
  display flex
  flex-direction column
  align-items flex-start
  justify-content flex-start
  width 100%

  &__list {
    display flex
    align-items center
    justify-content flex-start
    flex-wrap wrap
    font-weight: normal;
    list-style none
    gap 6px
    +below(530px) {
      align-items flex-start
    }

    .icon {
      min-width 0
      max-height 0
      height 8px
      width: 5px;

      svg {
        width 100%
        height 100%
        fill var(--pink)
      }
    }

    li {
      line-height 21px
      color var(--dark)

      a {
        color var(--dark)
      }
    }
  }

  &__link {
    transition var(--transition)
    color: var(--blue)
    position relative
    display flex
    font-weight normal
    align-items center
    gap 6px

    +below(530px) {
      display flex
    }
  }
}
</style>
